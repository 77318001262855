body {
	margin: 0;
	padding: 0;
}

#map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 1;
}

.centered_div_container {
	text-align: center;
	bottom: 0.1cm;
	pointer-events: none;
	position: fixed;
	width: 100%;
	z-index: 100;
}

.centered_div {
	bottom: 0.1cm;
	pointer-events: all;
	margin-left: auto;
	margin-right: auto;
	width: 4cm;
	z-index: 100;
}

.layer_container_div {
	align-items: center;
	vertical-align: middle;
	padding: 5px;
	border: solid;
	border-width: thin;
	border-color: black;
}

#logo {
	width: 20vmin;
	height: auto;
	z-index: 100;
	opacity: 0.66;
}

#logo_link {
	z-index: 100;
	width: auto;
	height: auto;
}

#legend {
	position: absolute;
	background: rgba(255,255,255, 0.6);
	padding: 5px;
	font-family: 'Open Sans', sans-serif;
}

#legend a {
	font-size: 13px;
	color: #404040;
	display: block;
	margin: 0;
	padding: 0;
	padding: 7.5px;
	text-decoration: none;
	border: 2px solid rgba(0, 0, 0, 0.25);
	text-align: center;
}

#legend a:last-child {
	border: none;
}

#legend a:hover {
	background-color: #f8f8f8;
	color: #404040;
}

#legend a.active {
	background-color: #3887be;
	color: #ffffff;
}

#legend a.active:hover {
	background: #3074a4;
}

.map_overlay {
	position: absolute;
	display: block;
	bottom: 0;
	right: 0;
	background: rgba(255,255,255,0.5);
	margin-right: 10px;
	margin-bottom: 80px;
	font-family: Arial, sans-serif;
	font-size: 14px;
	overflow: auto;
	border-radius: 4px;
	z-index: 1000;
	padding: 10px;
}

.legend_icon {
	vertical-align: middle;
}

#map_button_group {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1001;
	padding: 5px;
}

#toggle_button {
    background: rgba(255,255,255,0.5);
    margin-right: 16px;
    margin-bottom: 40px;
    font-family: Arial, sans-serif;
    border-radius: 4px;
}

#map-menu-wrapper {
    display: none;
}

.bool_switch {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 40px;
	height: 23px;
	margin: 0px;
}

.hr_divider {
	margin: 1px;
}

.bool_switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
	border-radius: 23px;
}

.slider:before {
	position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #2196F3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.layer_title_div {
	vertical-align: middle;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.layer_title_span {
	text-align: left;
	font-weight: bold;
	font-size: 18px;
	vertical-align: middle;
}

.layer_title_div_spacing {
	display: inline-block;
}
